@font-face {
  font-family: HeaderFont;
  src: url(./Fonts/Beachfly\ Free\ Trial.ttf);
}

@font-face {
  font-family: HeaderFont2;
  src: url(./Fonts/Bellerose.ttf)
}

.App{
  background-color: #1a2421;
}

.nav-item{
  color: antiquewhite;
}


.webTop{
  background-image: url("./Imgs/BackgroundImg.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.imgSize {
  width: 80%;
  max-width: 100%; 
}

.introBox {
  position: relative;
  background-image: url("./Imgs/BoxImg.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 80%;
  max-width: 800px; 
  margin: 20px auto; 
}

.introText {
  position: absolute;
  color: #463E36;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 57%; 
  left: -5%;
  transform: translateY(-50%);
  padding: 2px;
  width: 100%; 
  text-align: left;
}

.link{
  color: #463E36;
}




.HF{
  font-family: HeaderFont;
}

.HF2{
  font-family: HeaderFont2;
}

.line{
  background-color: #463E36;
  border-top: 3px solid #463E36;
}

.about{
  font-family: HeaderFont2;
}

.aboutMeBox{
  width: 275px
}

.container-fluid-nav div{
  display: flex;
justify-content: space-around;}

.titleFont{
  font-family: HeaderFont;
  color: antiquewhite;
  margin: 40px;
}

.spacer{
  height: 200px;
}

.spacer2{
  height: 490px;
}

.collage{
  background-image: url("./Imgs/collage.png");
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.2);
}

.NavBar{
  margin-top: 627px;
  background-color: #1a2421;
}

.navText{
  color: antiquewhite;
}

.schoolBox{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.aboutBox{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.schoolText{
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px;
  width: 500px;
  padding: 20px 20px;
  margin: 100px;
  background-color: antiquewhite;
  color: #463E36;
  border-radius: 7px;
}

.imgs{
  width:100%;
  padding: 20px 20px;
  margin: 20px 20px;
}

.center{
  display: flex;
  justify-content: center;
}

.row{
  display: flex;
  flex-direction: row;
}

.col{
  display: flex;
  flex-direction: column;
}
.space{
  margin: 0px 5px 0px 0px;
}

/* Carousel Styling */

.infoCarousel {
  width: 80%;
  margin: 50px;
}

.card2{
  width: 75%;
  height: 400px;
  background: #E9E2D0;
  box-shadow: -20px 0 35px -25px black, 20px 0 35px -25px black;
  display: flex;
  flex-direction: row;
  padding: 25px 25px ;
  margin: 25px 25px;
  border-radius: 7px;
  margin-left: 175px;
}

.imgSize2{
  display: flex;
  width: 90%;
  margin-top: -50px;
  justify-content: center;

}

.infoSize{
  width: 65%;
}

.cardCarousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory; 
}

.card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.chart{
  width: 70%;
}

@media (min-width: 200px) and (max-width: 600px) {
  .webTop {
    background-size: auto; 
    flex-direction: column; 
    align-items: center; 

  }

  .imgSize {
    width: 100%; 
  }

  .introBox {
    width: 100%;
  }

  .introText {
    position: static; 
    margin-top: 55%; 
    text-align: left; 
    width:290px;
    margin-left: 8%
  }

  .HF{
    font-size: 20px;
  }
  .HF2{
    font-size: medium;
  }

  .about{
    font-size: medium;
  }

  .fontSize{
    font-size: 12px;
  }

  .line{
    width: 200px;
  }

  .aboutMeBox{
    width: 165px;
    height: 55px;
    margin-bottom: 15px;
    overflow-y: scroll;
  }

  .spacer, .spacer2 {
    height: 100px; 
  }

  .schoolBox {
    flex-direction: column; 
    align-items: center; 
  }

  .schoolText {
    width: 100%; 
    margin: 20px; 
  }

  .imgs {
    width: 100%; 
    padding: 10px; 
    margin: 10px; 
  }

  .introBox,
  .imgSize {
    width: 100%;
  }


}


@media (min-width: 700px) and (max-width: 999px) {
  .webTop {
    background-size: auto; 
    flex-direction: column; 
    align-items: center; 

  }

  .imgSize {
    width: 100%; 
  }

  .introBox {
    width: 100%;
  }

  .introText {
    position: static; 
    margin-top: 55%; 
    text-align: left; 
    margin-left: -8%
  }



  .spacer, .spacer2 {
    height: 100px; 
  }

  .schoolBox {
    flex-direction: column; 
    align-items: center; 
  }

  .schoolText {
    width: 100%; 
    margin: 20px; 
  }

  .imgs {
    width: 100%; 
    padding: 10px; 
    margin: 10px; 
  }

  .introBox,
  .imgSize {
    width: 100%;
  }

}


@media (min-width: 200px) and (max-width: 600px) {
  .Carousel{
    width: 400px;
  }
  .infoCarousel{
    width: 500px;
    height: 600px;
    margin-left: -100px;
  }

  .imgSize2{
    display: flex;
    width: 100%;
    margin-top: -70px;
    margin-left: -37px;
    justify-content: center;
  }

  .card2{
    display: flex;
    flex-direction: column;
  }

  .card-text{
    overflow-y: scroll;
  }

}


@media (min-width: 700px) and (max-width: 999px) {

  .infoCarousel{
    width: 400px;
    display: flex;
    flex-direction: column;
  }

  .card2{
    display: flex;
    flex-direction: column;
  }

  .imgSize2{
    display: flex;
    width: 100%;
    margin-top: -70px;
    justify-content: center;
  }

  .card-text{
    overflow-y: scroll;
  }

}
